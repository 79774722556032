import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'


@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  //private wsIp:string='http://localhost:8080';
  private wsIp: string = 'http://movistarcr-fb.isoft-ste.com';
  private trainerapi:string  = "http://movistarcr-iabuilder.isoft-ste.com/"
  // private rsIp: string = 'http://localhost:5000';

  private token: string = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF91c3VhcmlvIjoxLCJpYXQiOjE1MzgzMTkyMzcsImV4cCI6MjA1NjcxOTIzN30.iNKbuFsLDOZ3UVKItCoxKADrnNal3B19U4dwcgpRqJQ';
  //globalip:string='http://192.168.1.6:8080';

  constructor(public http: HttpClient) { }

  loadIntents(){
    return this.http.get(this.trainerapi+'listIntents');
  }

  createIntent(params){
    return this.http.post(this.trainerapi+'createIntent', params);
  }

  deleteIntent(params){
    return this.http.post(this.trainerapi+'deleteIntent', params);
  }

  editIntent(params){
    return this.http.post(this.trainerapi+'editIntent', params);
  }

  testText(params){
    return this.http.post(this.trainerapi+'test', params);
  }

  searchIntent(params) {
    return this.http.post<any>(this.wsIp + '/api/bot/searchIntent/', params, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }
  
  backupIntent(params) {
    return this.http.post<any>(this.wsIp + '/api/bot/backupIntent/', params, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }
  
  updateCredentials(params){
    return this.http.post(this.trainerapi+'updateCredentials', params);
  }


  getButtonsTemplate(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getButtonsTemplate/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getTemplatesNode(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getTemplatesNode/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertTemplate(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertTemplate/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertButtonTemplate(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertButtonTemplate/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getinputspicker(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getinputspicker/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  clearNodeFilters(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/clearNodeFilters/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }
  getNodoAreas(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getNodoAreas/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }
  getNodoPaises(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getNodoPaises/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }
  getNodoSegmentos(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getNodoSegmentos/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }
  insertNodeArea(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertNodeArea/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }
  insertNodePais(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertNodePais/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }
  insertNodeSegmento(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertNodeSegmento/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }
  getAreas() {
    return this.http.post<any>(this.wsIp + '/api/bot/getAreas/', {}, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getSegmentos(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getSegmentos/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getPaises() {
    return this.http.post<any>(this.wsIp + '/api/bot/getPaises/', {}, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  setBotState(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/setBotState/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateNodeVariable(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateNodeVariable/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  testCustomWs(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/testCustomWs/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  testGetWs(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/testGetWs/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
    
  }

  sendJson(parameters) {
    /* const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams().set("jconf",parameters);
    let options = {params: params, headers: headers}; */
    
    return this.http.post<any>(this.wsIp + '/api/bot/generateBot/start', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
    // return this.http.post('http://builder.isoft-ste.com/', parameters, { responseType: 'text' });
  }

  testDPI(parameters) {
    return this.http.post<any>('http://35.229.102.133:5000/extractText', parameters);
  }

  loginAVI(parameters) {
    return this.http.post<any>(this.wsIp + '/api/avi/login/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getCountries() {
    return this.http.post<any>(this.wsIp + '/api/bot/getCountries/', { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getSegments(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getSegments/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getSegmentsCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getSegmentsCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertProfile(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertProfile/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateProfile(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateProfile/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getProfiles(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getProfiles/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getProfilesCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getProfilesCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getProfileDetail(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getProfileDetail/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getProfileDetailCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getProfileDetailCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertProfileDetail(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertProfileDetail/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getNode(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getNode/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getParameters(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getParameters/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getNodeTypes() {
    return this.http.post<any>(this.wsIp + '/api/bot/getNodeTypes/', {}, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertNode(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertNodeF2/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateNode(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateNodeF2/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertNodeOutput(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertNodeOutput/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateNodeOutput(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateNodeOutput/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertNodeProfile(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertNodeProfile/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertSegment(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertSegment/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getNodes(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getNodes/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getNodesCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getNodesCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getBot(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getBot/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getBots(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getBots/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getBotsCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getBotsCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteBot(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteBot/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteSegment(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteSegment/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getProfilesbyNode(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getProfilesbyNode/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getProfilesbyNodeCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getProfilesbyNodeCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertBot(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertBot/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateBotJson(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateBotJson/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getBroadcasts(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getBroadcasts/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getBroadcastsCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getBroadcastsCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertBroadcast(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertBroadcast/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getNodesbyType(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getNodesbyType/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateBot(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateBot/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteProfile(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteProfile/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateNodoFrases(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateNodoFrases/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getOutputsByNode(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getOutputsByNode/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getBucket() {
    return this.http.post<any>(this.wsIp + '/api/bot/getBucket/', { token: this.token }, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertFile(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertFile/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getFile(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getFile/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getFiles(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getFiles/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteFile(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteFile/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateFile(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateFile/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteFileError(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteFileError/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getRoot() {
    return this.http.post<any>(this.wsIp + '/api/bot/getRoot/', { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getComandos(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getComandos/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getComandosCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getComandosCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getOutputTypes(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getOutputTypes/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getInputTypes(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getInputTypes/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getOutputsComando(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getOutputsComando/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getInputsComando(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getInputsComando/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertActions(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertActions/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getTypeByName(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getTypeByName/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteProfileNumber(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteProfileNumber/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteNode(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteNode/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteBroadcast(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteBroadcast/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateBroadcast(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateBroadcast/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  copyNode(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/copyNode/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertArea(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertArea/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getArea(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getArea/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getAreaCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getAreaCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateArea(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateArea/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteArea(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteArea/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getUsersByArea(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getUsersByArea/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getUsersByAreaCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getUsersByAreaCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertUserArea(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertUserArea/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getConditions(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getConditions/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertDecisions(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertDecisions/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getRequirements(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getRequirements/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getRequirementsCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getRequirementsCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteRequirementCountry(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteRequirementCountry/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertRequirement(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertRequirement/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertRequirementCountry(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertRequirementCountry/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateRequirement(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateRequirement/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getProducts(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getProducts/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getProductsCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getProductsCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteProductCountry(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteProductCountry/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertProduct(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertProduct/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertProductCountry(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertProductCountry/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateProduct(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateProduct/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertInput(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertInput/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteInput(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteInput/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getInputsWebService(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getInputsWebService/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateInput(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateInput/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertHeader(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertHeader/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteHeader(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteHeader/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getHeaders(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getHeaders/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateCommandUrl(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateCommandUrl/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getPlans(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getPlans/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getPlansCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getPlansCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getCases(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getCases/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getCasesCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getCasesCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateCase(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateCase/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getFamilies(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getFamilies/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getFamiliesCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getFamiliesCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getPlansByCountry(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getPlansByCountry/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertFamily(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertFamily/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertFamilyPlan(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertFamilyPlan/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateFamily(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/updateFamily/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteFamily(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteFamily/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getPlansByFamily(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getPlansByFamily/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getConfigurations(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getConfigurations/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getConfigurationsCount(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getConfigurationsCount/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getProductByCountry(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getProductByCountry/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getFamilyByCountry(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getFamilyByCountry/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getScoringByCountry(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getScoringByCountry/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getSegmentByCountry(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getSegmentByCountry/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getChannelByCountry(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getChannelByCountry/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertConfiguration(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertConfiguration/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  insertConfigurationRequirement(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/insertConfigurationRequirement/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getRequirementByCountry(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getRequirementByCountry/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getRequirementsConfiguration(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getRequirementsConfiguration/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteConfiguration(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteConfiguration/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getComandosBroadcast(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getComandosBroadcast/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getBotsBroadcast(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getBotsBroadcast/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getNumber(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getNumber/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  redisInsertProfileNumber(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/redisInsertProfileNumber/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  redisDeleteProfileNumber(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/redisDeleteProfileNumber/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getProfilesByNodeGenerator(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getProfilesByNodeGenerator/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  deleteInputsHeaders(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/deleteInputsHeaders/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  sendResponseBackOffice(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/sendResponseBackOffice/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  getTramite(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/getTramite/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  setTramite(parameters) {
    return this.http.post<any>(this.wsIp + '/api/bot/setTramite/', parameters, { headers: { 'Authorization': 'Bearer ' + this.token } });
  }

  updateCommandBackOffice(codigo_comando, descripcion) {
    this.updateCommandUrl({ codigo: codigo_comando, url: this.wsIp + '/api/bot/insertCase/', tipo_peticion: 'post', tipo_consumo: '2' }).subscribe(data => {
      this.insertInput({ tipo_accion: "texto", parametro: "descripcion", codigo_comando: codigo_comando }).subscribe(data => {
        this.updateInput({ valor: descripcion, codigo_entrada: data[0].resultado }).subscribe(data => {

        })
      })
    });
  }
}
