import { MatSnackBar } from '@angular/material';
import { Router } from "@angular/router";
import { NodeEditor } from "rete";
import { LocalSaveService } from '../../local-save.service';
import { ServicesService } from "../../services.service";
import { Bot } from "./bot";
import { NodeBot } from "./node";
import { parse } from 'ts-node';

export class Generator{
    
    private start_node;
    private begin;
    private workflow = new Map<string, NodeBot>();
    private ArrayNodos = new Map<string,any>();
    private iaNodes = new Map<string,any>();
    bot;

    //generador de json

    constructor(private router: Router,private editor: NodeEditor, private services: ServicesService, private localSave: LocalSaveService, private snackBar: MatSnackBar){
        this.start_node = this.editor.nodes.find(x => x.data.type =="inicio");
    }

    contadorSeguridad = 0;
    contadorDpi = 0;
    contadorTemplate = 0;
    async start(){
        this.contadorSeguridad = 0;
        this.contadorDpi = 0;
        this.contadorTemplate = 0;
        for (let iterator = this.start_node.outputs.values(), o; !(o = iterator.next()).done; ) {
            for(let c of o.value.connections){
                let node_input = c.input.node;
                this.begin = "n_"+node_input.data.code;
                let node_output = await this.getNode(node_input, "BEGIN");
                node_output.start = true;
                this.workflow.set(node_output.nodid, node_output);
            }
        }
        this.bot = await this.getBot();
        console.log(this.bot);
        console.log(JSON.stringify(this.bot));
        console.log(this.iaNodes);

        this.services.updateCredentials({credentials: this.bot.credentials, projectID: this.bot.nproyecto}).subscribe((res2: any) => {
            this.services.loadIntents().subscribe((data:any) => {
                var intents = data;
                intents.forEach(intent => {
                    intent["procesado"]=false;
                });
                console.log(intents);
                this.iaNodes.forEach((iaNode,i) => {
                    var nExistenteDF = intents.filter(o => o.id_nodo.includes(i));
                    console.log(nExistenteDF);
                    if(nExistenteDF.length == 1)
                    {
                        //info: viene desde dialogflow
                        nExistenteDF[0].procesado = true
                        console.log("VIENE DESDE DF!!");
                        console.log(iaNode);

                    }
                    else if(nExistenteDF.length == 0)
                    {
                        //info: viene desde el arbol de nodos
                        this.services.searchIntent({codigo_nodo: iaNode.codigo_nodo}).subscribe((data:any) => {
                            var tam = data.length;
                            var intent = {};
                            if(tam == 0)
                            {
                                intent = {id_nodo: i, frases: [], is_fallback: false}
                            }
                            else
                            {
                                intent = {id_nodo: i, frases: data[0].frases.split("|"), is_fallback: false}
                            }
                            this.services.createIntent(intent).subscribe((data:any) => {
                                console.log(data);
                            });
                        });
                    }
                });
                intents.forEach(intent => {
                    console.log(intent);
                   /*  if(!intent.procesado)
                    {
                        console.log("BORRAR INTENT");
                        var frasesTxt = "";
                        intent.frases.forEach(frase => {
                            if(frasesTxt.length == 0)
                                frasesTxt = frase;
                            else
                                frasesTxt = frasesTxt + "|" +  frase;
                        });
                        this.services.backupIntent({codigo_nodo: parseInt(intent.id_nodo.replace("n_","")),frases: frasesTxt}).subscribe((data:any) => {
                            if(data)
                            {
                                this.services.deleteIntent({id_nodo_gc: intent.name}).subscribe((data:any) => {
                                    console.log(data);
                                });
                            }
                        });
                    } */
                });
            });
        });

        this.services.sendJson({jconf:JSON.stringify(this.bot)}).subscribe(data =>{
            let auxdata:any = data;
            // if(auxdata.toLowerCase().indexOf('processing')){
                this.services.setBotState([this.localSave.get('codigo_bot_seleccionado'),6]).subscribe(data => {
                    this.localSave.set('generate',true);
                    this.router.navigate(['home/bots']);
                });
            // }
            // else{
            //     // error vuelva a intentar
            //     this.services.setBotState([this.bot.botid, 1]).subscribe(data =>{});
            //     this.snackBar.open("Bot ya existente, ingrese otro nombre", '', { duration: 3000 });
            // }
            
        }); 
        
        
    }

    getBot():Promise<Bot>{
        return new Promise<Bot>((resolve, reject) =>{
            this.services.getBot({codigo: this.start_node.data.bot}).subscribe(bot =>{
                let bot_output:Bot = {botid: bot[0].codigo_bot, name: bot[0].nombre.toLowerCase().split(' ').join('-').split('_').join('-'), description: bot[0].descripcion, token: bot[0].token, IM: "facebook", credentials: bot[0].credentials, nproyecto: bot[0].nproyecto, workflow: Array.from(this.workflow.values())};
                resolve(bot_output);
            });
        });
    }

    recursivoTemplate(templates, nodos, indice, inodos, callback, pars)
    {
        var tam = templates.length;
        console.log(indice);
        if(indice < tam)
        {
            var template = templates[indice];
            this.services.getButtonsTemplate({codigo_template: template.codigo_template}).subscribe(buttons =>{
                //TITULO, SUB, IMAGEN DEL TEMPLATE
                pars = pars + template.titulo + "|" + template.subtitulo + "|" + template.url_img+ "|";
                buttons.forEach( (button, i) => {
                    if(button.link == null || button.link.length == 0)
                    {
                        var tipo = 'postback' 
                        var payload = nodos[inodos]
                        pars = pars + button.texto + "%" + tipo + "%" + payload
                        console.log(button.texto + "%" + tipo + "%" + payload);
                        inodos++;
                        if(i != buttons.length-1)
                            pars = pars + ";";
                    }
                    else
                    {
                        var tipo = 'web_url' 
                        var payload = button.link
                        pars = pars + button.texto + "%" + tipo + "%" + payload
                        console.log(button.texto + "%" + tipo + "%" + payload);
                        if(i != buttons.length-1)
                            pars = pars + ";";
                    }
                   
                });
                //TERMINO UN TEMPLATE EN pars

                indice++;
                if(indice != tam)
                    pars = pars + "@";

                this.recursivoTemplate(templates, nodos, indice, inodos, callback, pars);
            });
        }
        else
        {
            console.log(pars)
            callback(pars);
        }
    }

    async getNode(node_input, back):Promise<NodeBot>{
        return new Promise<NodeBot>(async (resolve, reject) =>{
            let node_output:NodeBot;
            console.log(node_input.data);
            if(node_input.data.type == "security")
            {
                this.contadorSeguridad++;
                let next = await this.getNodes(node_input);
                let pro = "public";
                
                let ws:NodeBot = {nodid: "n_sec_"+this.contadorSeguridad+"_0", profiles: pro, phrase: "", type: "out", class: "call_ws", pars: "post@standard@@@Content-Type:application/json@'https://doitbit.com:8082/api/isoft/secure/sms'@accessid%str%global%ws generate token", rules: {next:"n_sec_"+this.contadorSeguridad+"_1", back: back}};
                //this.workflow.set(ws.nodid, ws);

                let out:NodeBot = {nodid: "n_sec_"+this.contadorSeguridad+"_1", profiles: pro, phrase: "", type: "out", class: "print", pars: "Ingrese el token enviado a su teléfono :iphone:", rules: {next:"n_sec_"+this.contadorSeguridad+"_2", back: "n_sec_"+this.contadorSeguridad+"_0"}};
                this.workflow.set(out.nodid, out);

                let inc:NodeBot = {nodid: "n_sec_"+this.contadorSeguridad+"_2", profiles: pro, phrase: "", type: "in", class: "read", pars: "s3ct0k3n%str", rules: {next:"n_sec_"+this.contadorSeguridad+"_3", back: "n_sec_"+this.contadorSeguridad+"_1"}};
                this.workflow.set(inc.nodid, inc);

                let ws2:NodeBot = {nodid: "n_sec_"+this.contadorSeguridad+"_3", profiles: pro, phrase: "", type: "out", class: "call_ws", pars: "post@standard@@@Content-Type:application/json@'https://doitbit.com:8082/api/isoft/secure/validtoken'@accessid%str%global%ws generate token|s3ct0k3n%str%global%ws generate token", rules: {next:"n_sec_"+this.contadorSeguridad+"_4", back: "n_sec_"+this.contadorSeguridad+"_2"}};
                this.workflow.set(ws2.nodid, ws2);

                let check:NodeBot = {nodid: "n_sec_"+this.contadorSeguridad+"_4", profiles: pro, phrase: "", type: "out", class: "if_true_jump", pars: "1==_codes3ct0k3n%n_sec_"+this.contadorSeguridad+"_5|-1==_codes3ct0k3n%n_sec_"+this.contadorSeguridad+"_6", rules: {next:"END", back: "n_sec_"+this.contadorSeguridad+"_3"}};
                this.workflow.set(check.nodid, check);

                let ok:NodeBot = {nodid: "n_sec_"+this.contadorSeguridad+"_5", profiles: pro, phrase: "", type: "out", class: "print", pars: "Token Validado Exitosamente!", rules: {next:next, back: "n_sec_"+this.contadorSeguridad+"_4"}};
                this.workflow.set(ok.nodid, ok);

                let nook:NodeBot = {nodid: "n_sec_"+this.contadorSeguridad+"_6", profiles: pro, phrase: "", type: "in", class: "menu_inline", pars: "2@Token Invalido! Que deseas hacer?@Generar nuevo token%n_sec_"+this.contadorSeguridad+"_0|Salir%n_ia", rules: {next:"END", back: "n_sec_"+this.contadorSeguridad+"_4"}};
                this.workflow.set(nook.nodid, nook);                
            
                resolve(ws);
            }
            else if(node_input.data.type == "dpi")
            {

                this.contadorDpi++;
                let next = await this.getNodes(node_input);
                let pro = "public";
                
                let txt1:NodeBot = {nodid: "n_dpi_"+this.contadorDpi+"_0", profiles: pro, phrase: "", type: "out", class: "print", pars: "Envianos una fotografia de tu DPI", rules: {next:"n_dpi_"+this.contadorDpi+"_2", back: back}};
                //this.workflow.set(ws.nodid, ws);

                /* let gif:NodeBot = {nodid: "n_dpi_"+this.contadorDpi+"_1", profiles: pro, phrase: "", type: "out", class: "print", pars: "https://s3.amazonaws.com/cobranza/bots/7d96defa-ffb9-4183-a487-71ebff447e51.jpeg", rules: {next:"n_dpi_"+this.contadorDpi+"_2", back: "n_dpi_"+this.contadorDpi+"_0"}};
                this.workflow.set(gif.nodid, gif); */

                let read:NodeBot = {nodid: "n_dpi_"+this.contadorDpi+"_2", profiles: pro, phrase: "", type: "in", class: "read", pars: "im4g3ndp1%media", rules: {next:"n_dpi_"+this.contadorDpi+"_3", back: "n_dpi_"+this.contadorDpi+"_0"}};
                this.workflow.set(read.nodid, read);

                let extraxt:NodeBot = {nodid: "n_dpi_"+this.contadorDpi+"_3", profiles: pro, phrase: "", type: "out", class: "call_ws", pars: "post@standard@@@Content-Type:application/json@'http://dpi.isoft-ste.com/extractText'@im4g3ndp1%str%global%dpi ws", rules: {next:next, back: "n_dpi_"+this.contadorDpi+"_2"}};
                this.workflow.set(extraxt.nodid, extraxt);
            
                resolve(txt1);

            }
            else if(node_input.data.type == "template")
            {
                var next = await this.getNodes(node_input);
                console.log("XXXXXXXX NODOS SALIDAS XXXXXXXXXX");
                
                //let output = await this.getOutputs(node_input);
                //let temp_output = output.split("|");
                var temp_next = next.split("|");
                console.log(temp_next);
                var pro = "public";
                //template@titulo|subtitulo|https://2.bp.blogspot.com/-X_3AwQxZqPc/Vt9EXcsQFvI/AAAAAAAAAVY/k-Xyf4YjpRc/s1600/COLOR-NUTRIENTES.jpg|texto%postback%n_ia;texto%postback%n_ia@titulo2|subtitulo2|https://i.ytimg.com/vi/3sZHf3RK6L0/maxresdefault.jpg|texto%web_url%https://i.ytimg.com/vi/3sZHf3RK6L0/maxresdefault.jpg;texto%postback%n_ia
                //get templates
                    //get botones de template

                    
                this.services.getTemplatesNode({codigo_nodo: node_input.data.code}).subscribe(templates =>{
                    console.log("----------------TEMPLATES---------------------");
                    console.log(templates);
                    var tam = templates.length;
                    var pars = ""
                    this.recursivoTemplate(templates, temp_next, 0, 0,(npars)=>{
                        console.log(npars);
                        let ntemplate:NodeBot = {nodid: "n_template_"+node_input.data.code, profiles: pro, phrase: "", type: "in", class: "template", pars: npars, rules: {next: "n_ia", back: back}};
                        console.log(ntemplate);
                        this.workflow.set(ntemplate.nodid, ntemplate);

                        resolve(ntemplate);
                    },pars);
                    
                });
                
            }
            else
            {
                if(this.ArrayNodos.get(node_input.data.code)){
                    let aux = this.ArrayNodos.get(node_input.data.code);
                    if(aux.tipo == 0){
                        aux.node.rules.back= back;
                        resolve(aux.node);
                    }else if(aux.tipo == 1){
                        aux.node.rules.back= back;
                        this.workflow.set(aux.read.nodid, aux.read);
                        resolve(aux.node);
                    }
                }else{
                    this.services.getNode({codigo: node_input.data.code}).subscribe(async node =>{
                        let output;
                        let next;
                        let pro;
                        let temp_output;
                        let temp_next;
                        let temp;
                        let nodetipo=0;
                        let readmenu;
                        let decisionmenu;
                        console.log(node);
                        switch(node[0].tipo_nodo){
                            case "menu":
                                nodetipo = 1;
                                output = await this.getOutputs(node_input);
                                next = await this.getNodes(node_input);
                                pro = this.getProfiles(node_input);
                                let nombre_var = "m3nu1n9ut"+node[0].codigo_nodo;
                                temp_output = output.split("|");
                                temp_next = next.split("|");
                                let tmpcolumnas=node[0].columnas==undefined?1:node[0].columnas;
                                let tmpetiqueta=node[0].etiqueta==undefined?'':node[0].etiqueta.trim();
                                temp = tmpcolumnas + "@" + tmpetiqueta + "@";
                                for(let i = 0; i<temp_output.length; i++){
                                    temp += temp_output[i] + "%" + temp_next[i] + "|";
                                }
                                
                                if((node[0].frases.length > 0 && node[0].frases != "\"patterns\":[]")||node[0].nfrases)
                                    this.iaNodes.set("n_"+node[0].codigo_nodo+"_1",node[0])

                                node_output = {nodid: "n_"+node[0].codigo_nodo, profiles: pro, phrase: node[0].frases, type: "in", class: "menu_inline", pars: temp.substring(0, temp.length - 1), rules: {next: "n_ia", back: back}};
                                let read:NodeBot = {nodid: "n_"+node[0].codigo_nodo+"_2", profiles: pro, phrase: "", type: "in", class: "read", pars: nombre_var+"%str", rules: {next:"n_"+node[0].codigo_nodo+"_3", back: "n_"+node[0].codigo_nodo+"_1"}};
                                readmenu = read;
                                this.workflow.set(read.nodid, read);
                            break;
                            case "quickresponse":
                                output = await this.getOutputs(node_input);
                                next = await this.getNodes(node_input);
                                pro = this.getProfiles(node_input);
                                temp_output = output.split("|");
                                temp_next = next.split("|");
                                let tmpcolumnas2=node[0].columnas==undefined?1:node[0].columnas;
                                let tmpetiqueta2=node[0].etiqueta==undefined?'':node[0].etiqueta.trim();
                                let urlBtn = node[0].url_btn;
                                temp = tmpcolumnas2 + "@" + tmpetiqueta2 + "@" + urlBtn +"@";
                                for(let i = 0; i<temp_output.length; i++){
                                    temp += temp_output[i] + "%" + temp_next[i] + "|";
                                }
                                if((node[0].frases.length > 0 && node[0].frases != "\"patterns\":[]")||node[0].nfrases)
                                    this.iaNodes.set("n_"+node[0].codigo_nodo, node[0])
                            
                                node_output = {nodid: "n_"+node[0].codigo_nodo, profiles: pro, phrase: node[0].frases, type: "in", class: "quick", pars: temp.substring(0, temp.length - 1), rules: {next: "n_ia", back: back}};
                            break;
                            case "texto":
                                output = await this.getOutputs(node_input);
                                next = await this.getNodes(node_input);
                                pro = this.getProfiles(node_input);
                                if((node[0].frases.length > 0 && node[0].frases != "\"patterns\":[]")||node[0].nfrases)
                                    this.iaNodes.set("n_"+node[0].codigo_nodo, node[0])
                                node_output = {nodid: "n_"+node[0].codigo_nodo, profiles: pro, phrase: node[0].frases, type: "out", class: "print", pars: output, rules: {next: next, back: back}};
                            break;
                            case "multimedia":
                                output = await this.getOutputs(node_input);
                                next = await this.getNodes(node_input);
                                pro = this.getProfiles(node_input);
                                if((node[0].frases.length > 0 && node[0].frases != "\"patterns\":[]")||node[0].nfrases)
                                    this.iaNodes.set("n_"+node[0].codigo_nodo, node[0])
                                node_output = {nodid: "n_"+node[0].codigo_nodo, profiles: pro, phrase: node[0].frases, type: "out", class: "print", pars: output, rules: {next: next, back: back}};
                            break;
                            case "comando":
                                next = await this.getNodes(node_input);
                                pro = this.getProfiles(node_input);
                                let first_action = await this.getActions(node[0], next, back, pro);
                                node_output = first_action;
                            break;
                            case "decision":
                                output = await this.getConditions(node_input);
                                next = await this.getNodes(node_input);
                                pro = "public"
                                temp_output = output.split("|");
                                temp_next = next.split("|");
                                temp = "";
                                for(let i = 0; i<temp_output.length; i++){
                                    temp += temp_output[i] + "%" + temp_next[i] + "|";
                                }
                                if((node[0].frases.length > 0 && node[0].frases != "\"patterns\":[]")||node[0].nfrases)
                                    this.iaNodes.set("n_"+node[0].codigo_nodo, node[0])
                                node_output = {nodid: "n_"+node[0].codigo_nodo, profiles: pro, phrase: node[0].frases, type: "out", class: "if_true_jump", pars: temp.substring(0, temp.length - 1), rules: {next: next, back: back}};
                            break;
                            case "seleccion":
                                output = await this.getOutputs(node_input);
                                next = await this.getNodes(node_input);
                                pro = this.getProfiles(node_input);
                                temp_output = output.split("|");
                                temp = "";
                                for(let i = 0; i<temp_output.length; i++){
                                    temp += temp_output[i] + "%op" + i + "|";
                                }
                                temp = node[0].seleccion + "@" + temp;
                                if((node[0].frases.length > 0 && node[0].frases != "\"patterns\":[]")||node[0].nfrases)
                                    this.iaNodes.set("n_"+node[0].codigo_nodo, node[0])
                                node_output = {nodid: "n_"+node[0].codigo_nodo, profiles: pro, phrase: node[0].frases, type: "out", class: "select", pars: temp.substring(0, temp.length - 1), rules: {next: next, back: back}};
                            break;
                        }
                        this.ArrayNodos.set(node_input.data.code,{node:node_output,tipo:nodetipo,read:readmenu,decision:decisionmenu});
                        resolve(node_output);
                    });
                }
            }
        });
    }
    //pila = [];
    async getNodes(node):Promise<string>{//recorre todo el grafo y en result va el texto
        return new Promise<string>(async (resolve, reject) =>{
            let result: string = "";
            /* if(this.pila.indexOf(node.data.code)>-1)
            {   
                console.log("NODO OMITIDO "+node.data.code);
            }
            else
            { */
                //this.pila.push(node.data.code);
                for (let iterator = node.outputs.values(), o; !(o = iterator.next()).done; ) {
                    
                    for(let c of o.value.connections){
                        let node_input = c.input.node;
                        let node_output:NodeBot = await this.getNode(node_input, "n_"+node.data.code);
                        result += node_output.nodid + "|";
                        this.workflow.set(node_output.nodid, node_output);
                    }
                    if(o.value.connections.length === 0){
                        //result += this.begin+"|";
                        result += "n_ia|";
                    }
                }
            //}
            resolve(result.substring(0, result.length - 1));
        });
    }

    actions = [];
    getActions(node, next, back, profiles):Promise<NodeBot>{ //genera json de cada comando
        return new Promise<NodeBot>((resolve, reject) =>{
            this.services.getOutputsComando({codigo: node.codigo_nodo}).subscribe(async outputs =>{
                this.services.getInputsComando({codigo: node.codigo_nodo}).subscribe(async inputs => {
                    this.actions = outputs.concat(inputs);
                    if(this.actions.length > 0){
                        this.actions.sort((a,b)=>{return a.orden - b.orden}); //orden por id desde bd
                        console.log(this.actions);
                        let first_node = this.actions[0];
                        let aux = {type: "", class: "", output: ""};
                        this.updateAction(first_node, aux);
                        let first_action:NodeBot = {nodid: "n_"+node.codigo_nodo, profiles: profiles, phrase: node.frases, type: aux.type, class: aux.class, pars: aux.output, rules: {back: back}};
                        if((node.frases.length > 0 && node.frases != "\"patterns\":[]")||node.nfrases)
                            this.iaNodes.set("n_"+node.codigo_nodo, node)
                        this.workflow.set(first_action.nodid, first_action);
                        let one_action: boolean = false;
                        let last_action:NodeBot;
                        for(let i = 1; i<this.actions.length; i++){
                                this.updateAction(this.actions[i], aux);
                                let action:NodeBot = {nodid: "n_"+node.codigo_nodo+"_"+(i), profiles: profiles, phrase: "", type: aux.type, class: aux.class, pars: aux.output, rules: {next:"n_"+node.codigo_nodo+"_"+(i+1), back: "n_"+node.codigo_nodo+"_"+i}};
                                this.workflow.set(action.nodid, action);
                                one_action = true;
                                if(i === this.actions.length - 1){
                                    action.rules.next = next;
                                    last_action = action;
                                }
                        }
                        if(one_action){
                            first_action.rules.next = "n_"+node.codigo_nodo+"_1";
                        }else{
                            first_action.rules.next = next;
                            last_action = first_action;
                        }
                        if(node.tipo_consumo === '1'){//1 llamada a ws, 2 casos
                            let temp_ws = await this.getWebService(node, inputs, next, last_action.nodid, this.actions.length+1, profiles);
                            last_action.rules.next = temp_ws.nodid;
                        }else if(node.tipo_consumo === '3'){//1 llamada a ws, 2 casos
                            let temp_ws = await this.getWebService(node, inputs, next, last_action.nodid, this.actions.length+1, profiles, true);
                            last_action.rules.next = temp_ws.nodid;
                        }else if(node.tipo_consumo === '2'){
                            let temp_ws = await this.getWebService(node, inputs, next, last_action.nodid, this.actions.length+1, profiles);
                            temp_ws.class = "case_json";
                            last_action.rules.next = temp_ws.nodid;
                        }
                        resolve(first_action);
                    }else{
                        if(node.tipo_consumo === '1'){
                            let temp_ws = await this.getWebService(node, [], next, back, this.actions.length+1, profiles);
                            resolve(temp_ws);
                        }else if(node.tipo_consumo === '2'){
                            let temp_ws = await this.getWebService(node, [], next, back, this.actions.length+1, profiles);
                            temp_ws.class = "case_json";
                            resolve(temp_ws);
                        }
                    }
                });
            });
        });
    }

    getWebService(node, action, next, back, i, profiles, wsCustom = false):Promise<NodeBot>{
        return new Promise<NodeBot>((resolve, reject) =>{
            this.services.getHeaders({codigo: node.codigo_nodo}).subscribe(head =>{
                this.services.getInputsWebService({codigo: node.codigo_nodo}).subscribe(variable =>{//WICHO CAMBIO VALORES
                    let headers = "";
                    for(let h of head){
                        headers += h.llave + ":" + h.valor.replace("@","xXaRRobaXx") + "|";
                    }
                    
                    let variables = "";
                    for(let v of variable){
                        variables += v.parametro + this.updateType(v.tipo_accion) + "%" + '"' + v.valor + '"' + "|";
                    }
                    console.log(variables);
                    
                    let actions = "";
                    let aux = {type: "", class: "", output: ""};

                    for(let a of action){
                        //console.log(a.parametro);
                        
                        //console.log(this.actions)
                        var indice = this.actions.findIndex(function(action) { 
                            return action.parametro == a.parametro; 
                        });

                        this.updateAction(a, aux);
                        actions += aux.output + "%global";

                        if( (indice - 1) >= 0 )
                        {
                            if(this.actions[indice-1].tipo == "salida")
                            {
                                var pregunta = this.actions[indice-1].parametro;
                                actions = actions + "%" + pregunta;
                            }
                            else
                                actions = actions + "%" + "";
                        }
                        else
                            actions = actions + "%" + "";

                        actions = actions + "|";
                    }

                    if(variables != ""){
                        actions += "|";
                    }
                    var method = wsCustom ? 'rest':'standard';
                    var responseName = node.response_var==null?'':node.response_var;
                    var actionTxt = node.accion==null?'':node.accion;

                    let output = node.tipo_peticion + '@' + method + '@' + responseName + '@' + actionTxt + '@' + headers.substring(0, headers.length - 1) + "@'" + node.url + "'@" + actions.substring(0, actions.length - 1) + variables.substring(0, variables.length - 1);
                    console.log(output);
                    let webservice:NodeBot = {nodid: "n_"+node.codigo_nodo+"_"+i, profiles: profiles, phrase:(i-1)==0?node.frases:"", type: "out", class: "call_ws", pars: output, rules: {next:next, back:back}};
                    if((i-1)==0)
                        if((node.frases.length > 0 && node.frases != "\"patterns\":[]")||node.nfrases)
                            this.iaNodes.set("n_"+node.codigo_nodo+"_"+i,node);
                    this.workflow.set(webservice.nodid, webservice);
                    resolve(webservice);
                });
            });
            
        });
    }

    updateType(type){//tipos para ws
        if(type === "texto"){
            return "%str";
        }else if(type === "entero"){
            return "%int";
        }
    }

    updateAction(node, aux){ //setea tipos segun entradas o salidas
        if(node.tipo === "entrada"){
            aux.type = "in"; aux.class = "read";
            if(node.tipo_accion === "texto"){
                aux.output = node.parametro + "%str";
            }else if(node.tipo_accion === "entero"){
                aux.output = node.parametro + "%int";
            }else if(node.tipo_accion === "multimedia"){
                aux.output = node.parametro + "%media";
            }
        }else{
            aux.type = "out"; aux.class = "print";
            if(node.tipo_accion === "multimedia"){
                aux.output = node.direccion_parametro;
            }else{
                aux.output = node.parametro;
            }
        }
    }

    getOutputs(node):Promise<string>{
        let output:string = "";
        return new Promise<string>((resolve, reject) =>{
            this.services.getOutputsComando({codigo: node.data.code}).subscribe(out =>{
                for(let o of out){
                    if(node.data.type === "multimedia"){
                        output += o.direccion_parametro + "|";
                    }else{
                        output += o.parametro + "|";
                    }
                }
                resolve(output.substring(0, output.length - 1));
            });
        });
    }

    getConditions(node):Promise<string>{
        let conditions: string = "";
        return new Promise<string>((resolve, reject) =>{
            this.services.getConditions({codigo: node.data.code}).subscribe(cond =>{
                for(let c of cond){
                    conditions += c.condicion1 + c.signo_relacional + c.condicion2 + "|";
                }
                resolve(conditions.substring(0, conditions.length - 1));
            });
        });
    }

    getProfiles(node){
        return "public";
    }
    
}