import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent, HomeMenuComponent } from './home/home.component';
import { PerfilesComponent } from './perfiles/perfiles.component';
import { DragDropComponent } from './drag-drop/drag-drop.component';
import { BotsComponent } from './bots/bots.component';
import { NodosComponent } from './nodos/nodos.component';
import { BroadcastComponent } from './broadcasts/broadcast.component';
import { FileExplorerComponent } from './file-explorer/file-explorer.component';
import { ComandosComponent } from './comandos/comandos.component';
import { AreaComponent } from './areas/area.component';
import { PlansRequirementsComponent } from './planes-requisitos/planes-requisitos.component';
import { BackOfficeComponent } from './back-office/back-office.component';
import { MenuinicialComponent } from './menuinicial/menuinicial.component';
import { SegmentoComponent } from './segmento/segmento.component';

export const router: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'home', component: HomeComponent,
      children:[
                { path: 'home/bots', redirectTo: 'bots', pathMatch: 'full'},
                { path: 'bots', component: BotsComponent},
                { path: 'comandos', component: ComandosComponent},
                { path: 'broadcasts', component: BroadcastComponent},
                { path: 'perfiles', component: PerfilesComponent},
                { path: 'drag-drop', component: DragDropComponent},
                { path: 'nodos', component: NodosComponent},
                { path: 'file-explorer', component: FileExplorerComponent},
                { path: 'area', component: AreaComponent},
                { path: 'planes-requisitos', component: PlansRequirementsComponent},
                { path: 'back-office', component: BackOfficeComponent},
                { path: 'menu', component: HomeMenuComponent},
                { path: 'segmento', component: SegmentoComponent},
                { path: 'menuinicial', component: MenuinicialComponent}
      		   ]
    }
];

export const routes: ModuleWithProviders = RouterModule.forRoot(router);